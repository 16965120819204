import React, { useEffect, useState } from "react"
import styles from "../styles/Dashboard.module.scss"

export default function InputField({
  title,
  type,
  value,
  onChange,
  id,
  textarea,
  color = "#4361ee",
  info = false,
  extraBtn = false,
  onClick = null,
  style = {},
  disabled = false,
  maxLength = 0,
  disableTag = false,
  placeholder = " ",
  onKeyPress = () => {},
}) {
  useEffect(() => {
    const box = document.getElementById(`inputBox-${title}`)
    box.style.setProperty("--background-color", color)
  }, [])

  const Info = () => {
    return (
      <div className={styles.info}>
        <i className="bx bxs-info-circle"></i>
        <span>{info}</span>
      </div>
    )
  }

  return (
    <div className={styles.inputWrapper} style={{ ...style }}>
      {info !== false && <Info />}

      <div
        className={styles.inputBox}
        id={`inputBox-${title}`}
        style={{
          height: textarea ? "10rem" : "3.2rem",
          // marginTop: info ? 40 : 20,
        }}
      >
        {textarea ? (
          <>
            <textarea
              type={type}
              id={id}
              className={styles.input}
              value={value}
              autoComplete="off"
              placeholder={placeholder}
              onChange={(e) => onChange(e.target.value, id)}
              disabled={disabled}
              maxLength={maxLength}
              onKeyPress={onKeyPress}
            />
            <div className={styles.areaCount}>
              <span>{`${value.length}/1024`}</span>
            </div>
          </>
        ) : (
          <>
            <input
              type={type}
              id={id}
              className={styles.input}
              value={value}
              autoComplete="off"
              placeholder={placeholder}
              onChange={(e) => onChange(e.target.value, id)}
              disabled={disabled}
              onKeyPress={onKeyPress}
            />
            {maxLength !== 0 && (
              <div className={styles.areaCount}>
                <span>{`${value.length}/${maxLength}`}</span>
              </div>
            )}
          </>
        )}
        {!disableTag && (
          <label htmlFor={id} className={styles.label}>
            {title}
          </label>
        )}
      </div>
      {extraBtn && (
        <button className={styles.fieldBtn} onClick={onClick}>
          {extraBtn}
        </button>
      )}
    </div>
  )
}
