import React, { useState, useEffect } from "react"
import styles from "./styles/Profile.module.scss"
// import Logo from "../../public/imgs/armin-logo.svg"
// import Image from "next/image"
import InputField from "./components/InputField"
import { ColorRing } from "react-loader-spinner"
import Checkbox from "react-custom-checkbox"
import { FiCheck } from "react-icons/fi"
// import { useRouter } from "next/router"
import axios from "axios"
import { BrowserRouter as Router, useLocation } from "react-router-dom"

export default function WithRouter() {
  return (
    <Router>
      <App />
    </Router>
  )
}

function useQuery() {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

function App() {
  let query = useQuery()
  const oid = query.get("oid")
  // const [newsletter, setNewsletter] = useState(true)
  const [config, setConfig] = useState({})
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [inputValue, setInputValue] = useState({})
  const [assignedPrefs, setAssignedPrefs] = useState([])
  const [deletedPrefs, setDeletedPrefs] = useState([])
  const [initLoading, setInitLoading] = useState(true)
  const [company, setCompany] = useState({ imgUrl: "", displayName: "" })
  const [colors, setColors] = useState(null)

  useEffect(() => {
    if (!initLoading) {
      const check = document.getElementsByClassName("customCheckbox")[1]
      check?.style?.setProperty("--background-color", colors?.dark)
    }
  }, [initLoading, colors?.dark])

  const onChange = (text, name) => {
    setInputValue({ ...inputValue, [name]: text })
  }

  const getConfig = async () => {
    setInitLoading(true)
    try {
      const response = await axios.get(
        `https://api.chatarmin.com/api/contacts/${oid}`
      )

      console.log("response", response.data)

      if (response.status === 201) {
        const data = response.data
        const contact = data.alreadyContact
        setAssignedPrefs(contact.tags)
        setInputValue({
          firstname: contact.firstname,
          lastname: contact.lastname,
          email: contact.email,
        })
        setCompany(data.company)
        setColors(data.colors)
        // console.log("colors", data.colors)
        delete data.alreadyContact
        delete data.company
        delete data.colors
        // console.log("config data,", data)
        setConfig({ ...data })
      }
    } catch (error) {
      console.log("error")
    } finally {
      setInitLoading(false)
    }
  }

  useEffect(() => {
    if (oid) {
      getConfig()
    } else {
      window.location.replace("https://chatarmin.com")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oid])

  const prefClick = (pref) => {
    if (
      assignedPrefs?.filter((p) => p._id.toString() === pref._id.toString())
        .length === 0
    ) {
      setAssignedPrefs([...assignedPrefs, pref])
    } else {
      setDeletedPrefs([...deletedPrefs, pref])
      setAssignedPrefs([
        ...assignedPrefs.filter(
          (p) => p._id.toString() !== pref._id.toString()
        ),
      ])
    }
  }

  const updateContact = async () => {
    setLoading(true)
    console.log("newsletterdefault", config.newsletterDefault)
    try {
      const response = await axios.post(
        `https://api.chatarmin.com/api/contacts/${oid}`,
        {
          preferences: assignedPrefs,
          deletedPreferences: deletedPrefs,
          inputs: inputValue,
          subscribe: config.newsletterDefault,
        }
      )
      if (response.data.success) {
        setSuccess(true)
      }
    } catch (error) {
      console.log("error updating contact")
    } finally {
      setLoading(false)
    }
  }

  return (
    <div
      className={styles.profileContainer}
      style={{ backgroundColor: colors?.light }}
    >
      {!initLoading && (
        <>
          <div className={styles.imgBox}>
            <img
              src={company?.imgUrl}
              alt="logo"
              style={{ borderRadius: 50 }}
            />
          </div>
          <h1 style={{ fontWeight: "bold", color: colors?.dark, marginTop: 5 }}>
            {company?.displayName}
          </h1>
        </>
      )}

      <div className={styles.profileBox}>
        {success ? (
          <h3 style={{ textAlign: "center", color: "green" }}>
            Successfully updated your profile!
          </h3>
        ) : initLoading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ColorRing
              visible={true}
              height="50"
              width="50"
              ariaLabel="blocks-loading"
              wrapperStyle={{ marginRight: 10 }}
              wrapperClass="blocks-wrapper"
              colors={["#4361ee", "#3a0ca3", "#4cc9f0", "#4361ee", "#3a0ca3"]}
            />
            <h3>Loading</h3>
          </div>
        ) : (
          <>
            <h1>Profile creation</h1>
            {config?.inputs?.map((input, index) => (
              <InputField
                title={input.label}
                type={input.email ? "email" : "text"}
                id={input.label.toLowerCase()}
                value={inputValue[input.label.toLowerCase()] || ""}
                onChange={onChange}
                style={{ marginTop: 20 }}
                key={`input-${index}`}
                color={colors?.dark}
              />
            ))}

            {config?.preferences?.length > 0 && <h2>Manage preferences</h2>}
            <div className="preferences">
              {config?.preferences?.map((pref, index) => {
                const active =
                  assignedPrefs.filter(
                    (p) => p._id.toString() === pref._id.toString()
                  ).length > 0
                return (
                  <div
                    className={`onePref ${active ? "active" : ""}`}
                    onClick={() => prefClick(pref)}
                    key={`pref-${index}`}
                    id={`pref-${index}`}
                    style={{
                      borderColor: colors?.dark,
                      backgroundColor: active ? colors?.dark : "white",
                    }}
                  >
                    <span
                      style={{ color: active ? colors?.light : colors.dark }}
                    >
                      {pref.label}
                    </span>
                  </div>
                )
              })}
            </div>
            <Checkbox
              icon={<FiCheck color="white" size={24} />}
              name="subscribe-newsletter"
              checked={config?.newsletterDefault}
              onChange={(value, event) =>
                setConfig({ ...config, newsletterDefault: value })
              }
              containerClassName="customCheckbox"
              borderColor={colors?.dark}
              id="customCheck"
              className={`customCheckbox ${
                config?.newsletterDefault ? "checked" : ""
              }`}
              style={{ cursor: "pointer" }}
              labelStyle={{ marginLeft: 10, userSelect: "none" }}
              label="Subscribe to Whatsapp News"
            />
            <button
              className="save-btn"
              disabled={loading}
              onClick={updateContact}
              style={{ backgroundColor: colors?.dark, color: colors?.light }}
            >
              {loading ? (
                <ColorRing
                  visible={loading}
                  height="25"
                  width="25"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{ marginRight: 10 }}
                  wrapperClass="blocks-wrapper"
                  colors={[
                    "#4361ee",
                    "#3a0ca3",
                    "#4cc9f0",
                    "#4361ee",
                    "#3a0ca3",
                  ]}
                />
              ) : (
                "Submit"
              )}
            </button>
          </>
        )}
      </div>
    </div>
  )
}
